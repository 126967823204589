exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activites-culturelles-js": () => import("./../../../src/pages/activites-culturelles.js" /* webpackChunkName: "component---src-pages-activites-culturelles-js" */),
  "component---src-pages-activites-sportives-js": () => import("./../../../src/pages/activites-sportives.js" /* webpackChunkName: "component---src-pages-activites-sportives-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-anglais-cambridge-js": () => import("./../../../src/pages/anglais-cambridge.js" /* webpackChunkName: "component---src-pages-anglais-cambridge-js" */),
  "component---src-pages-chinois-hsk-js": () => import("./../../../src/pages/chinois-hsk.js" /* webpackChunkName: "component---src-pages-chinois-hsk-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-controle-des-connaissances-js": () => import("./../../../src/pages/controle-des-connaissances.js" /* webpackChunkName: "component---src-pages-controle-des-connaissances-js" */),
  "component---src-pages-curriculum-js": () => import("./../../../src/pages/curriculum.js" /* webpackChunkName: "component---src-pages-curriculum-js" */),
  "component---src-pages-dele-espagnol-js": () => import("./../../../src/pages/dele-espagnol.js" /* webpackChunkName: "component---src-pages-dele-espagnol-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-parents-js": () => import("./../../../src/pages/parents.js" /* webpackChunkName: "component---src-pages-parents-js" */),
  "component---src-pages-protection-de-la-vie-privee-js": () => import("./../../../src/pages/protection-de-la-vie-privee.js" /* webpackChunkName: "component---src-pages-protection-de-la-vie-privee-js" */),
  "component---src-pages-scolarite-js": () => import("./../../../src/pages/scolarite.js" /* webpackChunkName: "component---src-pages-scolarite-js" */),
  "component---src-pages-theatre-new-era-js": () => import("./../../../src/pages/theatre-new-era.js" /* webpackChunkName: "component---src-pages-theatre-new-era-js" */),
  "component---src-pages-vie-scolaire-js": () => import("./../../../src/pages/vie-scolaire.js" /* webpackChunkName: "component---src-pages-vie-scolaire-js" */)
}

